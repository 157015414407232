import React from "react";
import { BACKGROUND_LOADED, FOREGROUND_LOADED } from "./constants";

const loadedClassName = show => (show ? "show" : "hide");

const MAP_DESCRIPTION_TEXT = {
  'tipi-camp': 'Morning Yoga + Bride & Groom Camping location',
  'redwood-grove': 'Ceremony location - May 30, 2020 at 4:15pm',
  'bbq-area': 'Pre-ceremony drink location',
  'willow-glen': 'Family sleeping quarters',
  'cabin-camp': 'Guest camping & cabin location',
  'totem-ampitheatre': 'Welcome Party & Campfire Zone',
  'camp-entrance': 'Camp Entrance',
  'main-hall': 'Wedding Reception, Dinner & Dancing'
};

export const InteractiveMap = ({
  mapData,
  onLoadMapImages,
  allLoaded,
  onHoverItem,
  highlighted
}) => {
  const showImage = loadedClassName(allLoaded);
  return (
    <div id="map-base" className="container">
      <img
        className={`map-bg ${showImage}`}
        id="map-background"
        src="/assets/images/map/background1.png"
        alt="map background"
        onLoad={onLoadMapImages(BACKGROUND_LOADED)}
      />
      {mapData.map(({ id, name }) => {
        return (
          <img
            key={id}
            className={`map-detail coloring ${loadedClassName(
              highlighted === id
            )}`}
            id={`${id}-color`}
            alt={name}
            src={`/assets/images/map/${id}.png`}
          />
        );
      })}
      <img
        src="/assets/images/map/foreground.png"
        className={`map-detail ${showImage}`}
        id="map-foreground"
        alt="camp map foreground"
        onLoad={onLoadMapImages(FOREGROUND_LOADED)}
      />
      {mapData.map(({ id, name }) => (
        <button
          key={id}
          title={name}
          id={id}
          className="map-btn"
          onMouseOver={onHoverItem(id)}
          onMouseOut={onHoverItem(null)}
        >
          {name}
        </button>
      ))}
      {!allLoaded && (
        <img
          id="map-skeleton"
          className="bg-loading"
          src="/assets/images/map/map.svg"
        />
      )}
      {allLoaded && (
        <div className="map-title">
          {highlighted ? MAP_DESCRIPTION_TEXT[highlighted] : "Hover for info"}
        </div>
      )}
    </div>
  );
};
