export const createElement = (tagName = "div", options, children) => {
  const target = document.createElement(tagName);
  options &&
    Object.keys(options).forEach(key => {
      if (key !== "style") {
        target[key] = options[key];
      }
    });

  if (children) {
    const childElements = Array.isArray(children) ? children : [children];
    childElements.forEach(node => {
      target.appendChild(node);
    });
  }

  options.style &&
    Object.keys(options.style).forEach(style => {
      target.style[style] = options.style[style];
    });
  return target;
};
