import { ImageGallery } from "./gallery";
import ScrollBooster from "scrollbooster";

export const initGallery = () => {
  const entry = document.getElementById("gallery-entry");

  const gallery = new ImageGallery();
  entry.appendChild(gallery.el);
  const sb = new ScrollBooster({
    viewport: gallery.imagesContainer,
    content: gallery.imageViewHolder,
    mode: "x",
    onUpdate: data => {
       gallery.imagesContainer.scrollLeft = Math.round(data.position.x)
    }
  });
  // add observer to fade image opacity in and out
  if (window.IntersectionObserver) {
    gallery.addImageFadeInAnimation();
  }
};
