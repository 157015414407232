import React, { PureComponent } from "react";
import { InteractiveMap } from "./InteractiveMap";
import mapData from "../mapData.json";
import { BACKGROUND_LOADED, FOREGROUND_LOADED } from "./constants";

export class InteractiveMapWrapper extends PureComponent {
  constructor(props) {
    super(props);
    this.onLoad = this.onLoad.bind(this);
    this.onHoverItem = this.onHoverItem.bind(this);
    this.state = {
      mapData,
      waitUntilLoaded: [BACKGROUND_LOADED, FOREGROUND_LOADED],
      highlighted: null
    };
  }
  onLoad(itemLoaded) {
    return () => {
      this.setState(({ waitUntilLoaded }) => ({
        waitUntilLoaded: waitUntilLoaded.filter(target => target === itemLoaded)
      }));
    };
  }
  onHoverItem(item) {
    return () => {
      this.setState(() => ({
        highlighted: item
      }));
    };
  }
  render() {
    return (
      <InteractiveMap
        allLoaded={!this.state.waitUntilLoaded.length}
        onHoverItem={this.onHoverItem}
        highlighted={this.state.highlighted}
        onLoadMapImages={this.onLoad}
        mapData={this.state.mapData}
      />
    );
  }
}
