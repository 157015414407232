import { createElement } from "./utils/createElement";
import { arrayOfLength } from "./utils/arrayOfLength";

const IMAGE_COUNT = 36;
const IMAGE_PADDING = 100;
const IMAGE_WIDTH_PERCENT = 0.2;
const IMAGE_WIDTH_MAX = 250;
const IMAGE_RESIZE_DEBOUNCE = 500;
const calcImageWidth = () =>
  Math.min(window.innerWidth * IMAGE_WIDTH_PERCENT, IMAGE_WIDTH_MAX);
const calcImagePadding = () => window.innerWidth * 0.2;

const positionByIndex = ({ padding, width, index }) =>
  padding * (index + 1) + width * index;
const inPixels = value => `${value}px`;

const imageCaptions = {
  0: "Point Reyes, ca 2018",
  1: "Shane & Kristy's Wedding <br> Vacaville, ca 2018",
  2: "Yosemite 2017",
  3: "- Engagement - <br> Sayulita, Mexico. Aug 3, 2018",
  4: "Paris, France 2017",
  5: "Treasure Island Music Festival, 2016",
  6: "Guatapé, Colombia 2016",
  7: "Medellin, Colombia 2016",
  8: "Tony & Leigh's Wedding<br> oahu, hi 2016",
  9: "Jacob & Shannon's Wedding<br> Vermont 2015",
  10: "Outside Lands Music Festival, 2015",
  11: "Elbo Room<br> San Francisco, 2015",
  12: "- Engagement - <br> Sayulita, Mexico. Aug 3, 2018",
  13: "Conor & Jess' Wedding<br> Nevada City, ca 2018",
  14: "John & Wendy's Wedding<br> Las Vegas, nv 2018",
  15:  "Halloween 2017",
  16: "2 year Anniversary <br> San Francisco 2016",
  17: "Muir Woods w/ Ewok 2016",
  18: "Dustin & Dana Wedding,<br> Orange, ca 2017",
  19: "Thumpertown Beach - Cape Cod 2016",
  20: "Bocas del Toro, Panama 2017",
  21: "New Year's Eve<br> San Francisco, ca 2015",
  22: "New Year's Eve <br> San Luis Obisbo, 2017",
  23: "Halloween 2016",
  24: "Medellin, Colombia 2016",
  25: "Teotihuacan, Mexico 2016",
  26: "Ocean Beach<br> San Francisco 2016",
  27: "Amsterdam 2017",
  28: "dtla 2016",
  29: "Las Vegas 2018",
  30: "Darryl & Caitlin's Wedding<br> San Luis Obisbo, ca 2016",
  31: "Tonga Room 2016",
  32: "Big Sur 2016",
  33: "Ocean Beach 2019",
  34: "Catalina Island 2017",
  35: "Grey Fox Bluegrass Festival 2015"

}

export class ImageItem {
  constructor({ imageIndex, width, padding }) {
    const imageFrame = createElement("img", {
      src: "/assets/images/polaroid1.png",
      draggable: false,
      className: "polaroid-frame"
    });
    const mainImage = createElement("img", {
      className: "picture",
      src: `/assets/images/download-${imageIndex + 1}.jpg`,
      draggable: false
    });
    const fade = createElement("div", {
      className: "fade picture",
      style: {
        opacity: 0
      }
    });
    const caption = createElement("div", {
      innerHTML: imageCaptions[imageIndex] ,
      className: 'caption'
    });
    const el = createElement(
      "div",
      {
        className: "image-base",
        style: {
          left: inPixels(
            positionByIndex({
              width,
              padding,
              index: imageIndex
            })
          ),
          width: inPixels(width)
        }
      },
      [imageFrame, mainImage, fade, caption]
    );
    el.fade = fade;
    return el;
  }
}

export class ImageGallery {
  constructor() {
    this.imagesContainer = this.initImages();
    this.el = createElement(
      "div",
      {
        className: "base"
      },
      [this.imagesContainer]
    );
    this.initListeners();
    return this;
  }
  initImages() {
    this.imageWidth = calcImageWidth();
    this.imagePadding = calcImagePadding();
    this.images = arrayOfLength(IMAGE_COUNT).map(
      (_, i) => new ImageItem({ imageIndex: i, width: this.imageWidth, padding: this.imagePadding })
    );
    this.imageViewHolder = createElement(
      "div",
      {
        className: "images-container",
        style: {
          width: inPixels(
            positionByIndex({
              width: this.imageWidth,
              padding: this.imagePadding,
              index: IMAGE_COUNT + 1
            })
          )
        }
      },
      this.images
    );
    return createElement(
      "div",
      { className: "image-frame" },
      this.imageViewHolder
    );
  }
  initListeners() {
    let timer;
    window.addEventListener("resize", () => {
      clearTimeout(timer);
      setTimeout(this.resizeImages.bind(this), IMAGE_RESIZE_DEBOUNCE);
    });
  }
  resizeImages() {
    this.imageWidth = calcImageWidth();
    this.imagePadding = calcImagePadding();
    this.images.forEach((image, index) => {
      const width = this.imageWidth;
      image.style.width = inPixels(width);
      image.style.left = inPixels(
        positionByIndex({ width, padding: this.imagePadding, index })
      );
    });
    this.imageViewHolder.style.width = inPixels(
      positionByIndex({
        width: this.imageWidth,
        padding: this.imagePadding,
        index: IMAGE_COUNT + 1
      })
    );
  }
  addImageFadeInAnimation() {
    this.images.forEach(image => {
      const { fade } = image;
      fade.style.opacity = 1;
      const config = {
        root: null,
        rootMargin: "0px",
        threshold: 1.0
      };
      const observer = new IntersectionObserver(entries => {
        entries.forEach(item => {
          const opacity = item.isIntersecting ? 0 : 1;
          fade.style.opacity = opacity;
        });
      }, config);
      observer.observe(fade);
    });
  }
}
